import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Presentation from "./Presentation"

const Guest = ({ name, position, photo, linkedin, email }) => {
    const data = useStaticQuery(graphql`
        query {
            wp {
                contact {
                    acf {
                        contactEn {
                            contactEnPersonText
                            contactEnEmailSubject
                        }
                    }
                }
            }
        }
    `)
    const {
        contactEnPersonText,
        contactEnEmailSubject,
    } = data.wp.contact.acf.contactEn
    return (
        <Presentation
            photo={photo}
            name={name}
            position={position}
            linkedin={linkedin}
            email={email}
            contactText={contactEnPersonText}
            contactSubject={contactEnEmailSubject}
        />
    )
}
Guest.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    photo: PropTypes.object.isRequired,
    linkedin: PropTypes.string.isRequired,
    email: PropTypes.string,
}
export default Guest
