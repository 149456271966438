import React from "react"
import PropTypes from "prop-types"
import { isWebinar, isWebinarPodcast, isInterview } from "../../../../utils/getPostType"

import CommonBanner from "./common-banner/CommonBanner"
import PodcastBanner from "./podcast-banner/PodcastBanner"

const WebinarPodcastBanner = ({
    type,
    image,
    title,
    placeholdervideo,
    videoid,
    podcastId,
}) => {
    return (
        <>
            {isWebinar(type) || isWebinarPodcast(type) || isInterview(type) ? (
                <CommonBanner
                    placeholderVideo={placeholdervideo}
                    placeholderImage={image}
                    title={title}
                    type={type}
                    videoid={videoid}
                    podcastId={podcastId}
                />
            ) : (
                <PodcastBanner
                    title={title}
                    image={image}
                    type={type}
                    podcastId={podcastId}
                />
            )}
        </>
    )
}

WebinarPodcastBanner.propTypes = {
    type: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
    placeholdervideo: PropTypes.object,
    videoid: PropTypes.string,
    podcastId: PropTypes.string,
}

export default WebinarPodcastBanner
