import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import * as styles from "./styles"

import { getLabelType, getLabelIcon } from "../../../../utils/label"

import Button from "../../../ui/button/Button"
import Guest from "../guest/Guest"
import Icon from "../../../ui/icons/Icon"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser } from "../../../../utils"

const Details = ({ title, content, description, guests, type }) => {
    const [frontendHost, setFrontendHost] = useState("")

    useEffect(() => {
        if (isBrowser()) {
            setFrontendHost(window.location.href)
        }
    }, [])

    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    webinarpodcast {
                        acf {
                            webinarPodcastShare
                        }
                    }
                }
            }
        `
    )

    const { webinarPodcastShare } = data.wp.webinarpodcast.acf

    const socialMedia = [
        {
            url: `https://www.facebook.com/sharer/sharer.php?u=${frontendHost}`,
            icon: "facebook",
        },
        {
            url: `https://twitter.com/intent/tweet?text=${frontendHost}`,
            icon: "twitter",
        },
        {
            url: `https://www.linkedin.com/shareArticle?mini=true&url=${frontendHost}&title=${title}`,
            icon: "linkedin",
        },
    ]

    return (
        <>
            <div className={styles.Details__Informations}>
                <Button small label iconLeft={getLabelIcon(type)}>
                    {getLabelType(type)}
                </Button>

                <p
                    className={styles.Details__Title}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
            </div>
            <div className={styles.Details__Guests}>
                {guests &&
                    guests.map((guest, index) => {
                        const {
                            title,
                            acf: {
                                linkedinlink,
                                email,
                                position,
                                photo: {
                                    localFile: {
                                        childImageSharp: { gatsbyImageData },
                                    },
                                },
                            },
                        } = guest.guest
                        return (
                            <Guest
                                key={index}
                                name={title}
                                photo={gatsbyImageData}
                                position={position}
                                linkedin={linkedinlink}
                                email={email}
                            />
                        )
                    })}
            </div>
            <div className={styles.Details__SocialMedia}>
                <strong
                    dangerouslySetInnerHTML={{
                        __html: webinarPodcastShare,
                    }}
                />
                {socialMedia.map(({ icon, url }) => {
                    return (
                        <a rel="nofollow" href={url} target="_blank" key={icon}>
                            <Icon icon={icon} />
                        </a>
                    )
                })}
            </div>

            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div dangerouslySetInnerHTML={{ __html: description }} />
        </>
    )
}

Details.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    description: PropTypes.string,
    guests: PropTypes.array.isRequired,
    socialMedia: PropTypes.array,
    type: PropTypes.string.isRequired,
}

export default Details
