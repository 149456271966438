import React from "react"

import * as styles from "./styles"

import Button from "../button/Button"

const Presentation = ({ header, email, subject, buttonText, dataAtts }) => {
    return (
        <div className={`${styles.Cta} t-Dark`}>
            <h2
                dangerouslySetInnerHTML={{
                    __html: header,
                }}
                className={`h2--Small ${styles.Cta__Header}`}
            />

            <Button
                href={`mailto: ${email}?subject=${subject}`}
                dataAtts={dataAtts}
                iconRight="arrow2"
            >
                {buttonText}
            </Button>
        </div>
    )
}

export default Presentation
