import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import * as styles from "./styles"

import VideoPlayer from "../../../../common/video-player/VideoPlayer"
import Spreaker from "../../../../functional/spreaker/Spreaker"
import { H1 } from "../../../../ui/headers/Headers"
import Label from "../../../../ui/label/Label"
import useMediaContext from "../../../../../hooks/useMediaContext"

const CommonBanner = ({
    placeholderVideo,
    placeholderImage,
    videoid,
    podcastId,
    title,
    type,
}) => {
    const placeholder =
        placeholderVideo && placeholderVideo.mediaItemUrl
            ? { type: "video", src: placeholderVideo.mediaItemUrl }
            : {
                  type: "image",
                  alt: placeholderImage.altText,
                  src:
                      placeholderImage.localFile.childImageSharp
                          .gatsbyImageData,
              }
       
    const [triggerClick, setTriggerClick] = useState(false)
    const { setMedia } = useMediaContext()

    useEffect(() => {
        if (triggerClick) {
            triggerPlayButtonClick()
        }
    })

    const handlePlayButtonClick = () => {
        setTriggerClick(!triggerClick)
    }
    
    const triggerPlayButtonClick = () => {
        const id = videoid
        
        setMedia({ id, active: true, type: "video" })
        setTriggerClick(!triggerClick)
    }

    return (
        <section className={`${styles.CommonBanner} t-Dark`}>
            <div className={`row l-Inner ${styles.CommonBanner__Outer}`}>
                <div className={`small-12 ${styles.CommonBanner__Container}`}
                     role="button"
                     onClick={handlePlayButtonClick}
                     onKeyDown={handlePlayButtonClick}
                     tabIndex={0}
                >
                    <VideoPlayer
                        dataAtts={{
                            click:
                                "section-webinarpodcast-videoplayer-play-big",
                            name: "Play",
                        }}
                        id={videoid}
                        placeholder={placeholder}
                        showPlayButton
                        centerPlay
                        triggerPlayButtonClick={triggerPlayButtonClick}
                    />
                    <div className={styles.CommonBanner__Content}>
                        <Label type={type} />
                        <H1 content={title} 
                        />
                    </div>
                </div>
            </div>
            {podcastId && (
                <div className={`row l-Inner ${styles.CommonBanner__Podcast}`}>
                    <div className="small-12">
                        <Spreaker id={podcastId} />
                    </div>
                </div>
            )}
        </section>
    )
}

CommonBanner.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.object,
    placeholderImage: PropTypes.object,
    placeholdervideo: PropTypes.object,
    videoid: PropTypes.string,
    podcastid: PropTypes.string,
}

export default CommonBanner
