import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Presentation from "./Presentation"

const PodcastBanner = ({ image, podcastId, title, type }) => {
    const defaultData = useStaticQuery(
        graphql`
            query {
                wp {
                    webinarpodcast {
                        acf {
                            webinarPodcastBanner {
                                ...gatsbyFluidImage
                            }
                            webinarPodcastBannerMobile {
                                sourceUrl
                            }
                        }
                    }
                }
            }
        `
    )

    const {
        webinarPodcastBanner: {
            localFile: {
                childImageSharp: { gatsbyImageData: banner },
            },
        },
        webinarPodcastBannerMobile: { sourceUrl: background },
    } = defaultData.wp.webinarpodcast.acf

    return (
        <Presentation
            banner={
                image ? image.localFile.childImageSharp.gatsbyImageData : banner
            }
            background={background}
            title={title}
            type={type}
            podcastId={podcastId}
        />
    )
}

export default PodcastBanner
