import React from "react"

import * as styles from "./styles"

import Img from "gatsby-image"
import Icon from "../../../ui/icons/Icon"
import { GatsbyImage } from "gatsby-plugin-image"

const Presentation = ({ resources, downloadText }) => {
    return (
        <div className="row">
            {resources?.map((resource, index) => (
                <div
                    key={index + resource.title}
                    className={`small-12 large-6 xlarge-12 ${styles.Presentation__Outer}`}
                >
                    <a
                        target="_blank"
                        rel="noopener noreferrer nofollows"
                        className={styles.Presentation__Container}
                        href={resource.link}
                    >
                        <div className={styles.Presentation__Image}>
                            <GatsbyImage
                                style={{ height: "100%" }}
                                imgStyle={{ objectFit: "cover" }}
                                image={
                                    resource.image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                                alt=""
                            />
                        </div>

                        <div className={styles.Presentation__Content}>
                            <h3
                                dangerouslySetInnerHTML={{
                                    __html: resource.title,
                                }}
                            />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: resource.content,
                                }}
                            />
                            <strong
                                className={styles.Presentation__DownloadLink}
                            >
                                {downloadText}
                                <Icon icon="arrow2" />
                            </strong>
                        </div>
                    </a>
                </div>
            ))}
        </div>
    )
}

export default Presentation
