import React from "react"
import PropTypes from "prop-types"

import Spreaker from "../../../functional/spreaker/Spreaker";

import * as styles from "./styles"

const Podcast = ({ id }) => {
    return (
        <div className={styles.Podcast__Wrapper}>
            <Spreaker id={id} />
        </div>
    )
}

Podcast.propTypes = {
    id: PropTypes.string.isRequired,
}

export default Podcast
