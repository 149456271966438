import { useStaticQuery, graphql } from "gatsby"

const useInsightPhrases = () => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    webinarpodcast {
                        acf {
                            webinarPodcastThreeTiles {
                                threeTilesButtonText
                                threeTilesHeader
                                threeTilesSubheader
                            }
                        }
                    }
                }
            }
        `
    )
    const {
        wp: {
            webinarpodcast: {
                acf: {
                    webinarPodcastThreeTiles: {
                        threeTilesButtonText,
                        threeTilesHeader,
                        threeTilesSubheader,
                    },
                },
            },
        },
    } = data

    return {
        threeTiles: {
            buttonText: threeTilesButtonText,
            header: threeTilesHeader,
            subheader: threeTilesSubheader,
        },
    }
}

export default useInsightPhrases
