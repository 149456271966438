import React from "react"
import PropTypes from "prop-types"

import Presentation from "./Presentation"

const DetailsTabs = ({ active, tabs }) => {
    const filteredTabs = tabs
        .map(tab => {
            if (tab.condition) {
                return tab.component
            }
            return false
        })
        .filter(tab => tab !== false)

    return <Presentation active={active} tabs={filteredTabs} />
}

DetailsTabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    active: PropTypes.number.isRequired,
}
export default DetailsTabs
