import React, { useState, useMemo, useCallback } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import TabsSelect from "../components/single-page/webinar-podcast/tabs-select/TabsSelect"
import Cta from "../components/ui/cta/Cta"
import DetailsTabs from "../components/common/details-tabs/DetailsTabs"
import ThreeTiles from "../components/sections/three-tiles/ThreeTiles"
import Details from "../components/single-page/webinar-podcast/details/Details"
import Transcription from "../components/single-page/webinar-podcast/transcription/Transcription"
import Downloadable from "../components/single-page/webinar-podcast/downloadable/Downloadable"
import WebinarPodcastBanner from "../components/single-page/webinar-podcast/banner/WebinarPodcastBanner"
import Seo from "../components/functional/seo/Seo"
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs"
import useInsightPhrases from "../hooks/useInsightPhrases"
import Contact from "../components/sections/contact/Contact"
import usePostsContext from "../hooks/usePostsContext"
import Newsletter from "../components/single-page/webinar-podcast/newsletter/Newsletter"
import routes from "../config/routes"
import { isBrowser } from "../utils"
import Podcast from "../components/single-page/webinar-podcast/podcast/Podcast";

export const data = graphql`
    query($id: String!) {
        wpWebinar(id: { eq: $id }) {
            ...webinarPodcast
        }
        wp {
            webinarpodcast {
                acf {
                    webinarPodcastBreadcrumbs {
                        ... on WpPage {
                            link
                            title
                        }
                    }
                }
            }
            general {
                acf {
                    generalOgImage {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    }
`

const WebinarPodcast = ({ data }) => {
    const [tabActive, setTabActive] = useState(0)

    const {
        title,
        id,
        content,
        tags,
        modifiedGmt,
        acf: {
            overwritecta,
            overwritetabs,
            showcta,
            ctaoptions,
            showNewsletter,
            newsletterOptions,
            type,
            guests,
            description,
            resources,
            simpletexttranscription,
            placeholdervideo,
            image,
            ogImage,
            videoid,
            podcastid,
            tabsoptions,
        },
    } = data.wpWebinar

    const tabs = [
        {
            condition: true,
            component: (
                <Details
                    type={type}
                    title={title}
                    guests={guests}
                    content={content}
                    description={description}
                />
            ),
        },
        {
            condition: simpletexttranscription,
            component: <Transcription text={simpletexttranscription} />,
        },
        {
            condition: resources,
            component: <Downloadable resources={resources} />,
        },
    ]

    const defaultPhrases = useInsightPhrases()
    const allInsights = usePostsContext().insights.all

    const getSimilarInsights = useCallback(() => {
        const currentTags = tags.nodes.map(node => node.name)
        return (
            allInsights
                .filter(insight => {
                    const tags = insight.tags.nodes.map(node => node.name)
                    const tagMatches = currentTags.some(tag =>
                        tags.includes(tag)
                    )

                    return insight.id !== id && tagMatches
                })
                // shuffle all insights into a random order
                .sort(() => Math.random() - 0.5)
                .slice(0, 3)
        )
    }, [allInsights, id, tags.nodes])
    const similarInsights = useMemo(() => getSimilarInsights(), [
        getSimilarInsights,
    ])

    const podcastInBanner = (videoid && podcastid) ? false : true

    return (
        <Layout>
            <Seo
                lang="en"
                ogImage={
                    ogImage
                        ? ogImage.localFile.childImageSharp.gatsbyImageData
                              .images.fallback.src
                        : data.wp.general.acf.generalOgImage.localFile
                              .childImageSharp.gatsbyImageData.images.fallback
                              .src
                }
                title={title}
                description={content}
            >
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "PodcastEpisode",
                            "name": "${title}",
                            "description": "${content}",
                            "url": "${isBrowser() ? window.location.href : ""}",
                            "director": "Michał Grela",
                            "productionCompany": "Future Processing",
                            "producer": "Future Processing",
                            "datePublished": "${modifiedGmt}",
                            "associatedMedia": {
                                "@type": "MediaObject",
                                "contentUrl": "${image?.sourceUrl}"
                            },
                            "partOfSeries": {
                                "@type": "PodcastSeries",
                                "name": "IT Leadership Insights"
                            },
                            "image": "${image?.sourceUrl}",
                            "publisher": {
                                "@type": "Organization",
                                "name": "Future processing",
                                "url": "https://future-processing.com"
                            }
                        }
                    `}
                </script>
                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement":[
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "item":{
                                    "@id": "https://itinsights.tech",
                                    "name": "Home"
                                }
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "item":{
                                    "@id": "${
                                        isBrowser() ? window.location.href : ""
                                    }",
                                    "name": "${title}"
                                }
                            }
                        ]
                    }
                    `}
                </script>
            </Seo>

            <div className="t-Dark">
                <div className="row l-Inner">
                    <div className="small-12">
                        <Breadcrumbs
                            page="Insights hub"
                            link={routes.insights}
                            title={title}
                        />
                    </div>
                </div>
            </div>
            <WebinarPodcastBanner
                type={type}
                image={image}
                title={title}
                videoid={videoid}
                podcastId={podcastInBanner ? podcastid : null}
                placeholdervideo={placeholdervideo}
            />
            <TabsSelect
                tabActive={tabActive}
                setTabActive={setTabActive}
                customizedOptions={tabsoptions}
                overwrite={overwritetabs}
                resources={resources}
                transcript={simpletexttranscription}
            />
            <div className="row l-Inner">
                <div className="small-12 medium-7 large-8">
                    <DetailsTabs tabs={tabs} active={tabActive} />
                    { !podcastInBanner &&
                        <Podcast id={podcastid} />
                    }
                </div>
                <aside className="small-12 medium-4 medium-offset-1 large-3">
                    {showNewsletter && <Newsletter props={newsletterOptions} />}
                    {showcta && (
                        <Cta
                            overwrite={overwritecta}
                            customizedOptions={ctaoptions}
                            dataAtts={{
                                click: "section-webinarpodcast-rightcolumn-box",
                                name: "Promo box",
                            }}
                        />
                    )}
                </aside>
            </div>
            {similarInsights.length > 0 && (
                <ThreeTiles
                    props={{
                        type: "insights",
                        subheader: defaultPhrases.threeTiles.subheader,
                        header: defaultPhrases.threeTiles.header,
                        buttonText: defaultPhrases.threeTiles.buttonText,
                        customTiles: similarInsights,
                        showCustomTiles: true,
                        doubleBottomPadding: true,
                    }}
                />
            )}
            <Contact props={{ language: "en" }} />
        </Layout>
    )
}

export default WebinarPodcast
