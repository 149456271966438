import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./styles"

import Spreaker from "../../../../functional/spreaker/Spreaker"
import { H1 } from "../../../../ui/headers/Headers"
import Label from "../../../../ui/label/Label"

const Presentation = ({ banner, background, title, podcastId, type }) => {
    return (
        <section className={`${styles.Presentation} t-Dark`}>
            <div className={`row l-Inner ${styles.Presentation__Container}`}>
                <div className={`small-12 ${styles.Presentation__Banner}`}>
                    <div className={styles.Presentation__ImageContainer}>
                        <GatsbyImage
                            className={styles.Presentation__Image}
                            image={banner}
                        />
                        <div className={styles.Presentation__Content}>
                            <Label type={type} />
                            <H1 content={title} />
                        </div>
                    </div>
                </div>
                <div className="small-12">
                    <Spreaker background={background} id={podcastId} />
                </div>
            </div>
        </section>
    )
}

export default Presentation
