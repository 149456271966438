import React from "react"

import * as styles from "./styles"

import classnames from "classnames"
const Presentation = ({ tabs, active }) => {
    return (
        <div className={styles.DetailsTabs}>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={classnames(styles.DetailsTabs__Tab, {
                        [styles.DetailsTabs__TabIsActive]: active === index,
                    })}
                >
                    {tab}
                </div>
            ))}
        </div>
    )
}

export default Presentation
