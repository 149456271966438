import React from "react"
import PropTypes from "prop-types"

import * as styles from "./styles"

const Transcription = ({ text }) => {
    return (
        <div className={styles.Transcription}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
}

Transcription.propTypes = {
    text: PropTypes.string,
}

export default Transcription
