import React from "react"
import propTypes from "prop-types"

import * as styles from "./styles"
import HubspotForm from "../../../functional/hubspot/HubspotForm"
import {graphql, useStaticQuery} from "gatsby";

const Newsletter = ({props: {header, subheader, hubspotFormId, hubspotFormSlug, personalDataInfo, thankYouHeader, thankYouText}}) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    webinarpodcast {
                        acf {
                            webinarPodcastNewsletter {
                                subheader
                                header
                                hubspotFormId
                                hubspotFormSlug
                                personalDataInfo
                                thankYouHeader
                                thankYouText
                            }
                        }
                    }
                }
            }
        `
    )

    const {
        acf: {
            webinarPodcastNewsletter: {
                subheader: defaultSubheader,
                header: defaultHeader,
                hubspotFormId: defaultFormId,
                hubspotFormSlug: defaultFormSlug,
                personalDataInfo: defaultPersonalInfo,
                thankYouHeader: defaultThankYouHeader,
                thankYouText: defaultThankYouText,
            }
        },
    } = data.wp.webinarpodcast

    return (
        <section className={styles.Newsletter}>
            <h2 className="h2--Small"
                dangerouslySetInnerHTML={{__html: header || defaultHeader}}
            />
            <p dangerouslySetInnerHTML={{__html: subheader || defaultSubheader}}/>
            <div className={`columns small-12 ${styles.Newsletter__Form}`}>
                <HubspotForm
                    formId={hubspotFormId || defaultFormId}
                    slug={hubspotFormSlug || defaultFormSlug}
                    personalDataInfo={personalDataInfo || defaultPersonalInfo}
                    header={thankYouHeader || defaultThankYouHeader}
                    text={thankYouText || defaultThankYouText}
                />
            </div>
        </section>
    )
}

Newsletter.propTypes = {
    header: propTypes.string,
    subheader: propTypes.string,
    text: propTypes.string,
    hubspotFormId: propTypes.string,
    hubspotFormSlug: propTypes.string,
}

export default Newsletter
