import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Presentation from "./Presentation"

const Downloadable = ({ resources }) => {
    const defaultData = useStaticQuery(
        graphql`
            query {
                wp {
                    webinarpodcast {
                        acf {
                            webinarPodcastDownload
                        }
                    }
                }
            }
        `
    )
    return (
        <Presentation
            resources={resources}
            downloadText={
                defaultData.wp.webinarpodcast.acf.webinarPodcastDownload
            }
        />
    )
}
Downloadable.propTypes = {
    resource: PropTypes.array,
}
export default Downloadable
