import React from "react"

import * as styles from "./styles"

import dataAttributes from "../../../../utils/dataAttributes"

import LinkedinIcon from "../../../../images/linkedin.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import Icon from "../../../ui/icons/Icon"

const Presentation = ({
    photo,
    linkedin,
    name,
    position,
    email,
    contactSubject,
    contactText,
}) => {
    return (
        <div className={styles.Presentation}>
            <div className={styles.Presentation__Photo}>
                <GatsbyImage image={photo} alt={name} />
                <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className={styles.Presentation__SocialIcon}
                    href={linkedin}
                >
                    <img src={LinkedinIcon} alt="linkedin" />
                </a>
            </div>
            <div className={styles.Presentation__Container}>
                <div>
                    <p
                        className={styles.Presentation__Name}
                        dangerouslySetInnerHTML={{
                            __html: name,
                        }}
                    />
                    <p
                        className={`${styles.Presentation__Position} u-Secondary`}
                        dangerouslySetInnerHTML={{
                            __html: position,
                        }}
                    />
                </div>
                {email && (
                    <a
                        {...dataAttributes(
                            "section-webinarpodcast-post-contactbox",
                            "Contact box"
                        )}
                        className={styles.Presentation__MailLink}
                        href={`mailto: ${email}?subject=${contactSubject}`}
                    >
                        {contactText}
                        <Icon icon="arrow2" />
                    </a>
                )}
            </div>
        </div>
    )
}

export default Presentation
