import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Presentation from "./Presentation"

const TabsSelect = ({
    tabActive,
    setTabActive,
    customizedOptions,
    overwrite,
    resources,
    transcript,
}) => {
    const defaultData = useStaticQuery(
        graphql`
            query {
                wp {
                    webinarpodcast {
                        acf {
                            webinarPodcastTabs {
                                firstTabHeader
                                firstTabDescription
                                secondTabHeader
                                secondTabDescription
                                thirdTabHeader
                                thirdTabMultiply
                                thirdTabSingle
                            }
                        }
                    }
                }
            }
        `
    )

    const {
        firstTabHeader: defaultFirstTabHeader,
        firstTabDescription: defaultFirstTabDescription,
        secondTabHeader: defaultSecondTabHeader,
        secondTabDescription: defaultSecondTabDescription,
        thirdTabHeader: defaultThirdTabHeader,
        thirdTabMultiply: defaultFirstTabMultiply,
        thirdTabSingle: defaultThirdTabSingle,
    } = defaultData.wp.webinarpodcast.acf.webinarPodcastTabs

    const firstTabHeader =
        customizedOptions.firsttabheader && overwrite
            ? customizedOptions.firsttabheader
            : defaultFirstTabHeader
    const firstTabDescription =
        customizedOptions.firsttabdescription && overwrite
            ? customizedOptions.firsttabheader
            : defaultFirstTabDescription

    const secondTabHeader =
        customizedOptions.secondtabheader && overwrite
            ? customizedOptions.secondtabheader
            : defaultSecondTabHeader
    const secondTabDescription =
        customizedOptions.secondtabdescription && overwrite
            ? customizedOptions.secondtabdescription
            : defaultSecondTabDescription

    const thirdTabHeader =
        customizedOptions.thirdtabheader && overwrite
            ? customizedOptions.thirdtabheader
            : defaultThirdTabHeader
    const thirdTabSingle =
        customizedOptions.thirdtabsingle && overwrite
            ? customizedOptions.thirdtabsingle
            : defaultThirdTabSingle
    const thirdTabMultiply =
        customizedOptions.thirdtabmultiply && overwrite
            ? customizedOptions.thirdtabmultiply
            : defaultFirstTabMultiply

    const tabsModel = [
        { header: firstTabHeader, description: firstTabDescription },
    ]

    if (transcript) {
        tabsModel.push({
            header: secondTabHeader,
            description: secondTabDescription,
        })
    }
    if (resources) {
        tabsModel.push({
            header: thirdTabHeader,
            description:
                resources.length > 1
                    ? `${resources.length} ${thirdTabMultiply}`
                    : `${resources.length} ${thirdTabSingle}`,
        })
    }

    return (
        <Presentation
            tabs={tabsModel}
            tabActive={tabActive}
            setTabActive={setTabActive}
        />
    )
}

TabsSelect.propTypes = {
    tabActive: PropTypes.number.isRequired,
    setTabActive: PropTypes.func.isRequired,
    customizedOptions: PropTypes.object,
    resources: PropTypes.array,
    transcript: PropTypes.array,
}

export default TabsSelect
