import React from "react"

import * as styles from "./styles"

import dataAttributes from "../../../../utils/dataAttributes"
import classnames from "classnames"
import { createGtmTagClass } from "../../../../utils"

const Presentation = ({ tabs, tabActive, setTabActive }) => {
    return (
        <section className={styles.Presentation}>
            <div className="row l-Inner">
                <div className="small-12">
                    <ul className={styles.Presentation__Container}>
                        {tabs?.map((tab, index) => (
                            <li
                                {...dataAttributes(
                                    "section-webinarpodcast-tab",
                                    tab.header
                                )}
                                key={index + tab.header}
                                onClick={() => setTabActive(index)}
                                onKeyDown={() => setTabActive(index)}
                                role="presentation"
                                className={classnames(
                                    styles.Presentation__Tab,
                                    {
                                        [styles.Presentation__TabIsActive]:
                                            tabActive === index,
                                    },
                                    `${createGtmTagClass("WebinarTab")}`
                                )}
                            >
                                <strong>{tab.header}</strong>
                                <span className="u-Secondary">
                                    {tab.description}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Presentation
